import styles from "./result.module.scss";
import {
    Modal,
    QRCode,
    Button,
    Alert,
    message,
    Input,
    Spin,
    Image,
    Space,
} from "antd";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import error from "../../assets/error.png";
import success from "../../assets/success.png";
import { baseURL, get, post } from "../../axios/axios";
import { Checkbox, Carousel } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import "../Billing.scss";
import { isMobile, isWexin } from "../../common/const";
import { newConstructStore } from "./newStore";
import { handleDownLoad } from "../../Service/utils";
import useStreamRequest from "../../hooks/useStreamRequest";
import { loginInstance } from "../../Service/login";
import warning from "../../assets/warning.png";
import paymentModal from "../../components/PaymentModal";
import { useModal } from "@ebay/nice-modal-react";

export function EditResult({ paper, updateOrderNo }) {
    const [showPayModal, setShowPayModal] = useState(false);
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [redeemCode, setRedeemCode] = useState("");
    const [timerId, setTimerId] = useState("");
    const [payResult, setPayResult] = useState(false);
    const payInfoRef = useRef({});
    const [showPayResult, setShowPayResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [demoList, setDemoList] = useState([]);

    const usePaymentModal = useModal(paymentModal);
    const submitPay = async () => {
        try {
            if (!checked) {
                message.warning("请先阅读并勾选服务条款");
                return;
            }
            if (loading) {
                return;
            }
            if (isWexin && isMobile) {
                const res = await get(
                    `/member/wechat/a/url?orderNo=${paper.orderNo}`
                );
                window.location.href = res;
                return;
            }

            setLoading(true);
            const client = isMobile ? "H5" : "PC";
            const res = await post("/member/payment/pay", {
                orderNo: paper.orderNo,
                client: client,
            });

            if (client === "H5") {
                window.location.href = res.data.mwebUrl;
            } else {
                payInfoRef.current = {
                    ...(res.data || {}),
                };
                // setShowPayModal(true);
                // checkPay();
                usePaymentModal
                    .show({
                        orderNo: paper.orderNo,
                        price: getShowInfo().allPrice,
                    })
                    .then(() => {
                        setShowPayResult(true);
                        setPayResult(true);
                    });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const showCodeModal = async (e) => {
        e.stopPropagation();
        setShowRedeemModal(true);
    };

    const submitRedeemCode = async () => {
        try {
            const res = await post("/member/payment/redeemCode", {
                orderNo: paper.orderNo,
                code: redeemCode,
            });
            console.log("res", res);
            if (res && res.code === 0) {
                message.info("正在加速生成中，预计需要10分钟");
                setShowRedeemModal(false);
                setPayResult(true);
            } else {
                const msg = res && res.msg;
                message.error(
                    msg || "请检查您输入的兑换码是否正确，如果错误请重新输入。"
                );
            }
        } catch (error) {
            console.error(error);
            // message.error("请检查您输入的兑换码是否正确，如果错误请重新输入。");
        }
    };

    const onCancel = () => {
        window.clearTimeout(timerId);
        payInfoRef.current = {};
        setShowPayResult(false);
        setShowPayModal(false);
    };

    const toRePay = async () => {
        setShowPayResult(false);
        const res = await post("/member/payment/pay", {
            orderNo: paper.orderNo,
            client: "PC",
        });

        payInfoRef.current = res.data || {};
    };

    const firstCheckPay = async () => {
        // const res = await get("/member/payment/pay/result/" + data.orderNo);
        // if (res && res.data) {
        //   setPayResult(res.data.code === 0);
        // }
    };

    const innerUpOrderNo = async (
        currentTableCheck = tableCheck,
        currentGongshiCheck = gongshiCheck,
        currentDaimaCheck = daimaCheck,
        currentTuCheck = tuCheck,
        currentAigcCheck = aigcCheck
    ) => {
        let customReferenceDocument = undefined;
        const reference = newConstructStore.stepWenxian.selectIdxList
            .map((id) => {
                if (id > 0) {
                    return newConstructStore.stepWenxian.wenxianList[id].id;
                } else {
                    customReferenceDocument =
                        newConstructStore.stepWenxian.wenxianList[0];
                    return "";
                }
            })
            .filter(Boolean)
            .join(",");

        let ss = getSs(
            currentTableCheck,
            currentGongshiCheck,
            currentDaimaCheck,
            currentTuCheck,
            currentAigcCheck
        );
        const res = await post("/ai/paper/new/w", {
            majorName: newConstructStore.step1.majorName,
            keywords: newConstructStore.step1.keywords.join(","),
            paperTitle:
                newConstructStore.step2.titles[
                    newConstructStore.step2.selectIdx
                ],
            paperAbstracts:
                newConstructStore.step4.titles[
                    newConstructStore.step4.selectIdx
                ],
            paperNewOutline: newConstructStore.step4.outlines,
            reference,
            customReferenceDocument,
            ss,
            orderNo: paper.orderNo,
            type: newConstructStore.step1.type,
        });
        updateOrderNo(res.data.orderNo);
    };

    useEffect(() => {
        (async () => {
            firstCheckPay();
            getPayDemo();
            handleEnStreamStartRequest();
        })();
    }, []);

    const checkPay = async () => {
        if (timerId) {
            window.clearTimeout(timerId);
        }

        try {
            const res = await get(
                "/member/payment/pay/result/" + paper.orderNo
            );
            if (res && res.data && res.data.code === 0) {
                setShowPayResult(true);
                setPayResult(res.data.code === 0);

                setTimeout(() => {
                    if (res.data.code === 0) {
                        onCancel();
                    }
                }, 1000);
            } else {
                const id = window.setTimeout(() => {
                    checkPay();
                }, 1000);
                setTimerId(id);
            }
        } catch (err) {
            console.error(err);
        }
    };

    function openUrl(url) {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
    }

    const exportPaper = async () => {
        const { data, msg } = await get(`/ai/paper/status/${paper.orderNo}`);
        if (data) {
            message.success("论文下载完成");
            handleDownLoad(
                "https://cqingyoung.com/ai/word/paper/export/" + paper.orderNo
            );
            return;
        }

        if (data === null) {
            message.error(msg || "查询订单失败，请联系客服");
        }

        if (data === false) {
            message.info("正在加速生成中，预计需要10分钟");
        }
    };

    async function getPayDemo() {
        const { data } = await get("/ai/paper/r");
        setDemoList(data);
    }

    const [checked, setChecked] = useState(false);
    const [tuCheck, setTuCheck] = useState(
        newConstructStore.step1.autoLightTypes?.includes("DIAGRAM")
    );
    const [tableCheck, setTableCheck] = useState(
        newConstructStore.step1.autoLightTypes?.includes("TABLE")
    );
    const [gongshiCheck, setGongshiCheck] = useState(
        newConstructStore.step1.autoLightTypes?.includes("FORMULA")
    );
    const [daimaCheck, setDaimaCheck] = useState(
        newConstructStore.step1.autoLightTypes?.includes("CODE")
    );
    const [aigcCheck, setAigcCheck] = useState(false);

    // 1是表格 2是公式 3是图 4是代码 5是降AI率
    function getShowInfo() {
        let paperPrice = paper.paperItem ? +paper.paperItem.price : 0;
        let tableCount = 0;
        let gongshiCount = 0;
        let tuCount = 0;
        let daimaCount = 0;
        let professionalPrice = 0;
        let allPrice = paper.paperItem ? +paper.paperItem.price : 0;

        // 处理表格
        if (tableCheck) {
            const tables =
                newConstructStore.extraTypes.filter((it) => it.id === 1) || {};
            if (tables.length) {
                tableCount = tables.length;
                professionalPrice += tables.reduce(
                    (acc, cur) => acc + cur.price,
                    0
                );
                allPrice += tables.reduce((acc, cur) => acc + cur.price, 0);
            }
        }

        // 处理公式
        if (gongshiCheck) {
            const gongshi =
                newConstructStore.extraTypes.filter((it) => it.id === 2) || {};
            if (gongshi.length) {
                gongshiCount = gongshi.length;
                professionalPrice += gongshi.reduce(
                    (acc, cur) => acc + cur.price,
                    0
                );
                allPrice += gongshi.reduce((acc, cur) => acc + cur.price, 0);
            }
        }

        // 处理图
        if (tuCheck) {
            const tu =
                newConstructStore.extraTypes.filter((it) => it.id === 3) || {};
            if (tu.length) {
                tuCount = tu.length;
                professionalPrice += tu.reduce(
                    (acc, cur) => acc + cur.price,
                    0
                );
                allPrice += tu.reduce((acc, cur) => acc + cur.price, 0);
            }
        }

        // 处理代码
        if (daimaCheck) {
            const daima =
                newConstructStore.extraTypes.filter((it) => it.id === 4) || {};
            if (daima.length) {
                daimaCount = daima.length;
                professionalPrice += daima.reduce(
                    (acc, cur) => acc + cur.price,
                    0
                );
                allPrice += daima.reduce((acc, cur) => acc + cur.price, 0);
            }
        }

        // 处理降AI率 - 固定价格19.9
        if (aigcCheck) {
            allPrice += 19.9;
            professionalPrice += 19.9;
        }

        return {
            paperPrice,
            allPrice,
            tableCount,
            gongshiCount,
            tuCount,
            daimaCount,
            professionalPrice,
        };
    }

    function getLv(table, gongshi) {
        if (table && gongshi) {
            return 3;
        }

        if (table && !gongshi) {
            return 1;
        }
        if (!table && gongshi) {
            return 2;
        }
        return 0;
    }

    /**
     * 获取选中类型的字符串
     * @param {boolean} tableCheck - 表格复选框状态
     * @param {boolean} gongshiCheck - 公式复选框状态
     * @param {boolean} daimaCheck - 代码复选框状态
     * @param {boolean} tuCheck - 图复选框状态
     * @param {boolean} aigcCheck - 降AI率复选框状态
     * @returns {string} - 返回类型字符串，多个类型用逗号分隔，都未选中返回"0"
     */
    const getSs = (
        tableCheck,
        gongshiCheck,
        daimaCheck,
        tuCheck,
        aigcCheck
    ) => {
        // 存储选中的类型
        const selectedTypes = [];

        // 检查每个选项的状态并添加对应的类型
        if (tableCheck) selectedTypes.push("1");
        if (gongshiCheck) selectedTypes.push("2");
        if (tuCheck) selectedTypes.push("3");
        if (daimaCheck) selectedTypes.push("4");
        if (aigcCheck) selectedTypes.push("8"); // 添加aigc类型

        // 如果没有选中任何类型，返回"0"
        if (selectedTypes.length === 0) {
            return "0";
        }

        // 返回用逗号连接的类型字符串
        return selectedTypes.join(",");
    };

    const [extraLoading, setExtraLoading] = useState(false);

    async function onExtraCheckChange(type, checked) {
        try {
            setExtraLoading(true);

            let newTableCheck = tableCheck;
            let newGongshiCheck = gongshiCheck;
            let newDaimaCheck = daimaCheck;
            let newTuCheck = tuCheck;
            let newAigcCheck = aigcCheck;

            // 更新对应的状态
            if (type === "table") {
                newTableCheck = !!checked;
                setTableCheck(newTableCheck);
            } else if (type === "gongshi") {
                newGongshiCheck = !!checked;
                setGongshiCheck(newGongshiCheck);
            } else if (type === "daima") {
                newDaimaCheck = !!checked;
                setDaimaCheck(newDaimaCheck);
            } else if (type === "tu") {
                newTuCheck = !!checked;
                setTuCheck(newTuCheck);
            } else if (type === "aigc") {
                newAigcCheck = !!checked;
                setAigcCheck(newAigcCheck);
            }

            // 使用新的状态值调用函数
            await innerUpOrderNo(
                newTableCheck,
                newGongshiCheck,
                newDaimaCheck,
                newTuCheck,
                newAigcCheck
            );
        } catch (error) {
            // 错误处理,还原状态
            if (type === "table") {
                setTableCheck(!checked);
            } else if (type === "gongshi") {
                setGongshiCheck(!checked);
            } else if (type === "daima") {
                setDaimaCheck(!checked);
            } else if (type === "tu") {
                setTuCheck(!checked);
            } else if (type === "aigc") {
                setAigcCheck(!checked);
            }
        } finally {
            setExtraLoading(false);
        }
    }

    const auth = loginInstance.getHeaders();
    const authHeaders = auth ? { Authorization: auth } : {};

    const [enAbstractsCompleted, setEnAbstractsCompleted] = useState(false);
    const [enAbstracts, setEnAbstracts] = useState("");
    const handleMessage = useCallback((data) => {
        if (data.chatToken && data.chatToken !== "[DONE]") {
            // 解码 Unicode 编码的字符
            const decodedToken = JSON.parse(`"${data.chatToken}"`);
            setEnAbstracts((prev) => prev + decodedToken);
        }
    }, []);

    const handleError = useCallback((error) => {
        setEnAbstractsCompleted(false);
        console.error("Stream error:", error);
    }, []);

    const handleEnSteramFinish = useCallback(() => {
        setEnAbstractsCompleted(true);
    }, []);
    const {
        streamRequest: enStreamRequest,
        loading: enStreamLoading,
        enSteamError,
    } = useStreamRequest({
        url: baseURL + "/ai/paper/gen/en/abstracts",
        headers: {
            ...authHeaders,
        },
        onMessage: handleMessage,
        onError: handleError,
        onFinish: handleEnSteramFinish,
    });

    const handleEnStreamStartRequest = async () => {
        try {
            // 发起第一个请求
            await enStreamRequest({
                majorName: newConstructStore.step1.majorName,
                keywords: newConstructStore.step1.keywords.join(","),
                paperTitle:
                    newConstructStore.step2.titles[
                        newConstructStore.step2.selectIdx
                    ],
                paperAbstracts:
                    newConstructStore.step4.titles[
                        newConstructStore.step4.selectIdx
                    ],
                orderNo: paper.orderNo,
            });
        } catch (error) {
            console.error("初始化请求失败:", error);
        }
    };

    const [yinyanCompleted, setYinyanCompleted] = useState(false);
    const [yinyan, setYinyan] = useState("");
    const handleYinyanMessage = useCallback((data) => {
        if (data.chatToken && data.chatToken !== "[DONE]") {
            const decodedToken = JSON.parse(`"${data.chatToken}"`);
            setYinyan((prev) => prev + decodedToken);
        }
    }, []);

    const handleYinyanError = useCallback((error) => {
        setYinyanCompleted(false);
        console.error("Stream error:", error);
    }, []);
    const handleYinyanFinish = useCallback(() => {
        setYinyanCompleted(true);
        console.log("Stream completed");
    }, []);
    const {
        streamRequest: yinyanStreamRequest,
        loading: yinyanStreamLoading,
        yinyansteamError,
    } = useStreamRequest({
        url: baseURL + "/ai/paper/gen/content",
        headers: {
            ...authHeaders,
        },
        onMessage: handleYinyanMessage,
        onError: handleYinyanError,
        onFinish: handleYinyanFinish,
    });

    const handleStartYinyanStreamRequest = useCallback(() => {
        yinyanStreamRequest({
            majorName: newConstructStore.step1.majorName,
            keywords: newConstructStore.step1.keywords.join(","),
            paperTitle:
                newConstructStore.step2.titles[
                    newConstructStore.step2.selectIdx
                ],
            paperAbstracts:
                newConstructStore.step4.titles[
                    newConstructStore.step4.selectIdx
                ],
            orderNo: paper.orderNo,
        });
    }, [yinyanStreamRequest]);

    // 如果需要监控加载状态
    const isLoading = enStreamLoading || yinyanStreamLoading;

    // 如果需要监控错误状态
    const error = enSteamError || yinyansteamError;

    return (
        <div className={styles.result}>
            <div className={styles.resultPage}>
                <div className={styles.pageTitle}>
                    {paper.paperItem && paper.paperItem.remark}
                </div>
                <div>
                    {/*{paper.list &&*/}
                    {/*  paper.list.map((item, idx) => (*/}
                    {/*    <div key={idx}>*/}
                    {/*      <div className={styles.chapterTitle}>{item.chapter}</div>*/}
                    {/*      <div className={styles.subHeading}>{item.subheadings}</div>*/}
                    {/*      <div className={styles.content}>{item.content}</div>*/}
                    {/*    </div>*/}
                    {/*  ))}*/}
                    {/*摘要*/}
                    <h2
                        style={{
                            textAlign: "center",
                            marginTop: "32px",
                            marginBottom: "32px",
                            fontWeight: "bold",
                        }}
                    >
                        摘要
                    </h2>
                    <div className={styles.content}>
                        {
                            newConstructStore.step4.titles[
                                newConstructStore.step4.selectIdx
                            ]
                        }
                    </div>
                    {/* 英文摘要 */}
                    <h2
                        style={{
                            textAlign: "center",
                            marginTop: "32px",
                            marginBottom: "32px",
                            fontWeight: "bold",
                        }}
                    >
                        英文摘要
                    </h2>
                    <div className={"mt40"}></div>
                    <div className={styles.content}>{enAbstracts}</div>
                    {/* 引言 */}
                    <h2
                        style={{
                            textAlign: "center",
                            marginTop: "32px",
                            marginBottom: "32px",
                            fontWeight: "bold",
                        }}
                    >
                        一、引言
                    </h2>
                    <div className={styles.content}>{yinyan}</div>
                    {isLoading ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                height: "100px",
                            }}
                        >
                            <Spin />
                        </div>
                    ) : null}
                </div>
                <div className={styles.pay}>
                    <div className={styles.hideTop}></div>
                    <div className={styles.payContent}>
                        {payResult ? (
                            <div
                                onClick={exportPaper}
                                className={styles.payBtn}
                            >
                                下载论文
                            </div>
                        ) : (
                            <div
                                className='flex'
                                style={{ flexDirection: "column" }}
                            >
                                <Spin spinning={extraLoading}>
                                    <div className={"flex justify-center"}>
                                        {/*继续预览英文摘要*/}
                                        {!enAbstractsCompleted ? (
                                            <Button
                                                type='primary'
                                                loading={
                                                    loading ||
                                                    extraLoading ||
                                                    isLoading
                                                }
                                                className={`${styles.payBtn} mr-2`}
                                                onClick={() => {
                                                    handleEnStreamStartRequest();
                                                }}
                                            >
                                                <div>继续预览</div>
                                            </Button>
                                        ) : null}
                                        {/*继续预览引言*/}
                                        {enAbstractsCompleted &&
                                        !yinyanCompleted ? (
                                            <Button
                                                type='primary'
                                                loading={
                                                    loading ||
                                                    extraLoading ||
                                                    isLoading
                                                }
                                                className={`${styles.payBtn} mr-2`}
                                                onClick={() => {
                                                    handleStartYinyanStreamRequest();
                                                }}
                                            >
                                                <div>继续预览</div>
                                            </Button>
                                        ) : null}
                                        {/*预览完成显示解锁全篇*/}
                                        {enAbstractsCompleted &&
                                        yinyanCompleted ? (
                                            <Button
                                                type='primary'
                                                loading={
                                                    loading || extraLoading
                                                }
                                                className={styles.payBtn}
                                                onClick={submitPay}
                                            >
                                                <div>
                                                    ￥
                                                    {getShowInfo().allPrice ||
                                                        ""}
                                                    解锁全篇
                                                </div>
                                            </Button>
                                        ) : null}
                                    </div>
                                    {paper?.paperItem?.id === 1 && (
                                        <>
                                            <div className={styles.extra}>
                                                <Checkbox
                                                    checked={tuCheck}
                                                    onChange={(e) =>
                                                        onExtraCheckChange(
                                                            "tu",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        需要图
                                                    </b>
                                                </Checkbox>
                                                <Checkbox
                                                    checked={tableCheck}
                                                    onChange={(e) =>
                                                        onExtraCheckChange(
                                                            "table",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        需要表格
                                                    </b>
                                                </Checkbox>
                                                <Checkbox
                                                    checked={gongshiCheck}
                                                    onChange={(e) =>
                                                        onExtraCheckChange(
                                                            "gongshi",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        需要公式
                                                    </b>
                                                </Checkbox>
                                                <Checkbox
                                                    checked={daimaCheck}
                                                    onChange={(e) =>
                                                        onExtraCheckChange(
                                                            "daima",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        需要代码
                                                    </b>
                                                </Checkbox>
                                            </div>
                                            <div
                                                className={`${styles.extra} flex justify-center`}
                                            >
                                                <Checkbox
                                                    checked={aigcCheck}
                                                    onChange={(e) =>
                                                        onExtraCheckChange(
                                                            "aigc",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        降AI率
                                                    </b>
                                                </Checkbox>
                                                <Checkbox
                                                    checked={true}
                                                    disabled={true}
                                                >
                                                    <b
                                                        style={{
                                                            color: "#24be58",
                                                        }}
                                                    >
                                                        包过降重
                                                    </b>
                                                </Checkbox>
                                            </div>
                                        </>
                                    )}
                                </Spin>

                                <div className={styles.payTip}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e) =>
                                            setChecked(e.target.checked)
                                        }
                                    >
                                        <span
                                            style={{
                                                marginLeft: "4px",
                                                color: "#666",
                                            }}
                                        >
                                            请先阅读并同意
                                            <a
                                                style={{ color: "#666" }}
                                                className={styles.fuwuLink}
                                                href={`${window.location.protocol}//${window.location.host}/agreement`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                《服务条款》
                                            </a>
                                        </span>
                                    </Checkbox>
                                    <div
                                        style={{
                                            color: "#999",
                                            fontSize: "12px",
                                            marginTop: "2px",
                                        }}
                                        onClick={showCodeModal}
                                    >
                                        我有兑换码
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {demoList && demoList.length && (
                        <div className={styles.demoList}>
                            <NotificationOutlined
                                style={{ color: "rgb(36, 190, 88)" }}
                            />
                            <Carousel
                                style={{ width: "300px", margin: "0 0 0 10px" }}
                                dotPosition='left'
                                autoplay={true}
                                autoplaySpeed={1000}
                            >
                                {demoList.map((item, idx) => {
                                    return <div key={idx}>{item}</div>;
                                })}
                            </Carousel>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={showPayModal}
                width={550}
                footer={null}
                onCancel={onCancel}
                maskClosable={false}
                title={"解锁全文"}
                className='p0-ant-modal'
            >
                {showPayResult ? (
                    <div className='pay-result'>
                        <div
                            className='result'
                            style={
                                payResult ? { padding: "16px 0 20px 0" } : {}
                            }
                        >
                            <p className='tips'>
                                {payResult
                                    ? "恭喜您，支付成功"
                                    : "很遗憾，支付失败"}
                            </p>
                            <img
                                src={payResult ? success : error}
                                className='image'
                                style={
                                    payResult ? { margin: "16px 0 20px 0" } : {}
                                }
                            />
                            {!payResult ? (
                                <Button
                                    className='action_btn'
                                    onClick={toRePay}
                                >
                                    <span>重新支付</span>
                                </Button>
                            ) : (
                                <Button onClick={onCancel}>
                                    <span>完成</span>
                                </Button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='w-pay'>
                        <div className={"w-pay-alert"}>
                            <Image src={warning} width={16}></Image>
                            <span className={"text-[#F7941D] text-xs ml-[6px]"}>
                                付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮
                            </span>
                        </div>

                        <div className='title'>请使用微信扫一扫</div>
                        <p className='order'>订单号：{paper.orderNo}</p>
                        <div className='qr-code'>
                            {/*<QRCode value={payInfoRef.current.codeUrl} />*/}
                            <QRCode
                                size={174}
                                value={payInfoRef.current.codeUrl}
                            />
                        </div>
                        <p className='cost-tips'>
                            <span className='text'>支付金额</span>
                            <span className='value'>
                                ¥{getShowInfo().allPrice || ""}
                            </span>
                        </p>

                        <div className='button-group'>
                            <Button type='primary' onClick={checkPay}>
                                <span>付款成功</span>
                            </Button>
                        </div>
                        <p className='text-[#999] text-xs px-10 mt-2'>
                            虚拟产品，支付前请仔细确认，结果由AI根据您提供的信息自动生成，仅供参考，支付成功后立即生效，不支持退款。
                        </p>
                        <p className='tips'>如遇支付问题，请联系客服微信</p>
                    </div>
                )}
            </Modal>

            <Modal
                open={showRedeemModal}
                width={790}
                footer={null}
                onCancel={() => setShowRedeemModal(false)}
                maskClosable={false}
                title='请输入兑换码'
            >
                <Input
                    style={{ marginTop: "16px" }}
                    placeholder='请输入兑换码'
                    value={redeemCode}
                    onChange={(e) => setRedeemCode(e.target.value)}
                />
                <div
                    style={{ marginTop: "20px", justifyContent: "flex-end" }}
                    className='flex'
                >
                    <Button type='primary' onClick={submitRedeemCode}>
                        确定
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
