import { Modal, Button, Image, Radio, Alert, QRCode } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import success from "../../assets/success.png";
import error from "../../assets/error.png";
import warning from "../../assets/warning.png";
import { useState, useEffect, useRef } from "react";
import { get, post } from "../../axios/axios";
import { globalData } from "../../Service/utils";

const PaymentModal = NiceModal.create(({ orderNo, price }) => {
  const modal = useModal();
  const [payResult, setPayResult] = useState(false);
  const [showPayResult, setShowPayResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payType, setPayType] = useState(1); // 1:微信 2:支付宝
  const payInfoRef = useRef({});
  const [alipayUrl, setAlipayUrl] = useState("");

  useEffect(() => {
    if (modal.visible) {
      submitPay(1);
    }
  }, [modal.visible]);

  const submitPay = async (newPayType) => {
    try {
      setLoading(true);
      const res = await post("/member/payment/pay", {
        orderNo,
        client: "PC",
        payType: newPayType || payType,
      });

      if (newPayType === 1 && res.data?.codeUrl) {
        payInfoRef.current = res.data;
      } else if (newPayType === 2 && res.data?.form) {
        setAlipayUrl(res.data.form);
      }
      checkPay();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const checkPay = async () => {
    globalData.timerId && window.clearTimeout(globalData.timerId);

    try {
      const res = await get("/member/payment/pay/result/" + orderNo);
      if (res?.data?.code === 0) {
        setShowPayResult(true);
        setPayResult(true);
        setTimeout(() => {
          modal.resolve(res.data);
          onCancel();
        }, 1000);
      } else {
        const id = window.setTimeout(() => {
          checkPay();
        }, 1000);
        globalData.timerId = id;
      }
    } catch (err) {
      console.error(err);
      setShowPayResult(true);
      setPayResult(false);
      modal.reject(err);
    }
  };

  const toRePay = async () => {
    setShowPayResult(false);
    submitPay(1);
  };

  const onCancel = () => {
    console.log("onCancel");

    globalData.timerId && window.clearTimeout(globalData.timerId);
    payInfoRef.current = {};
    setPayResult(false);
    setShowPayResult(false);
    setPayType(1);
    setAlipayUrl("");
    modal.hide();
  };

  return (
    <Modal
      open={modal.visible}
      width={550}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      title={"解锁全文"}
      className='p0-ant-modal'
    >
      {showPayResult ? (
        <div className='pay-result'>
          <div
            className='result'
            style={payResult ? { padding: "16px 0 20px 0" } : {}}
          >
            <p className='tips'>
              {payResult ? "恭喜您，支付成功" : "很遗憾，支付失败"}
            </p>
            <img
              src={payResult ? success : error}
              className='image'
              style={payResult ? { margin: "16px 0 20px 0" } : {}}
            />
            {!payResult ? (
              <Button className='action_btn' onClick={toRePay}>
                <span>重新支付</span>
              </Button>
            ) : (
              <Button onClick={onCancel}>
                <span>完成</span>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className='w-pay'>
          <Alert
            message='付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮'
            type='warning'
            showIcon
            className='w-pay-alert'
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            <Radio.Group
              onChange={(e) => {
                setPayType(e.target.value);
                submitPay(e.target.value);
              }}
              value={payType}
            >
              <Radio.Button value={1}>微信支付</Radio.Button>
              <Radio.Button value={2}>支付宝</Radio.Button>
            </Radio.Group>
          </div>
          <div className='title' style={{ marginTop: "16px" }}>
            {payType === 1 ? "请使用微信扫一扫" : "请使用支付宝扫一扫"}
          </div>
          <p className='order'>订单号：{orderNo}</p>
          <div className='qr-code'>
            {payType === 1 ? (
              payInfoRef.current.codeUrl ? (
                <QRCode
                  value={payInfoRef.current.codeUrl}
                  size={200}
                  icon={null}
                />
              ) : (
                <div className='text-[#999] text-sm w-[170px] h-[170px] flex items-center justify-center'>
                  正在加载二维码...
                </div>
              )
            ) : null}
            {payType === 2 ? (
              alipayUrl ? (
                <iframe
                  style={{
                    width: "170px",
                    height: "170px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                  src={alipayUrl}
                  title='支付宝支付'
                ></iframe>
              ) : (
                <div className='text-[#999] text-sm min-w-[170px] min-h-[170px] flex items-center justify-center'>
                  正在加载二维码...
                </div>
              )
            ) : null}
          </div>
          <p className='cost-tips'>
            <span className='text'>支付金额</span>
            <span className='value'>¥{price}</span>
          </p>

          <div className='button-group'>
            <Button type='primary' onClick={checkPay}>
              <span>付款成功</span>
            </Button>
          </div>
          <p className='text-[#999] text-xs px-10 mt-2'>
            虚拟产品，支付前请仔细确认，结果由AI根据您提供的信息自动生成，仅供参考，支付成功后立即生效，不支持退款。
          </p>
          <p className='tips'>如遇支付问题，请联系客服微信</p>
        </div>
      )}
    </Modal>
  );
});

export default PaymentModal;
