import { makeAutoObservable } from "mobx";
import { message } from "antd";
import { get, post } from "../../axios/axios";
import { Buy } from "../Buy/Buy";
import { Redeem } from "../Buy/RedeemCode";
import paymentModal from "../../components/PaymentModal/index.jsx";
import NiceModal from "@ebay/nice-modal-react";
class ReduceStore {
  curStep = 0;

  step2selected = [];
  step2Service = {
    basic: 1,
    type: 1, // 1 智能 2强力
  };
  step2 = {
    loading: false,
    list: [],
    uid: "",
    order: {},
  };

  fetchId = 0;
  step3 = {
    status: 0,
    loading: true,
    list: [],
  };
  steps = [{ title: "上传论文" }, { title: "选择段落" }, { title: "降重结果" }];
  constructor() {
    this.init();
    makeAutoObservable(this);
  }

  init(opts = {}) {
    this.curStep = 0;
    this.step1 = {
      file: "",
    };
    this.step2selected = [];
    this.step2Service = {
      basic: 1,
      type: 1, // 1 智能 2强力
    };
    this.step2 = {
      list: [],
      uid: "",
      order: {},
    };
    this.fetchId = 0;
    this.step3 = {
      status: 0,
      loading: true,
      list: [],
    };

    if (opts.orderNo) {
      this.step2.order.orderNo = opts.orderNo;
      this.curStep = 2;
    }
  }

  get totalCount() {
    return this.step2selected.reduce((sum, cur) => {
      sum += cur.text ? cur.text.length : 9;
      return sum;
    }, 0);
  }

  onStep1FileChange(file) {
    this.step1.file = file;
  }
  toStep2 = (res) => {
    message.success("论文上传成功");
    this.step2 = { ...res.response.data, order: {} };
    console.log("xx", this.step2, res);
    this.step2selected = this.step2.list.filter((i) => +i.mark === 2);
    this.getOrderPrice();
    setTimeout(() => {
      this.curStep = 1;
    }, 500);
  };

  onStep2TogglePars = (index, checked) => {
    if (checked) {
      this.step2selected.push(index);
    } else {
      const idx = this.step2selected.indexOf(index);
      this.step2selected.splice(idx, 1);
    }
    this.step2selected = [...this.step2selected];
    this.getOrderPrice();
  };

  onStep2Service = (type, value) => {
    if (type === "basic" && !value && !this.step2Service.type) {
      message.info("请至少选择一项");
      return;
    }

    if (type === "type" && !value && !this.step2Service.basic) {
      message.info("请至少选择一项");
      return;
    }
    this.step2Service[type] = value ? 1 : 0;
    this.step2Service = { ...this.step2Service };
    this.getOrderPrice();
  };

  async getOrderPrice() {
    if (!this.step2selected.length) {
      message.info("请选择段落");
      this.step2.order.basicPrice = 0;
      this.step2.order.powerfulPrice = 0;
      this.step2.order.totalAmount = 0;
      return;
    }
    this.fetchId = this.fetchId + 1;
    const fetchId = this.fetchId;
    this.step2.loading = true;
    const res = await post("/ai/ai/jc/order", {
      uid: this.step2.uid,
      ids: this.step2selected.map((it) => it.id),
      type: this.step2Service.type,
      basic: this.step2Service.basic,
    }).finally(() => {
      this.step2.loading = false;
    });

    if (fetchId !== this.fetchId) {
      return;
    }

    // total = basic+ 用户选的type是1？智能去AI：强力去AI
    console.log(res);
    this.step2.order = { ...res.data };
  }

  onStep2Pay = () => {
    console.log("xx", this.step2.order);

    if (!this.step2selected.length) {
      message.info("请选择段落");
      return;
    }
    // Buy({ ...this.step2.order, price: this.step2.order.totalAmount }).then(
    //   (hasPay) => {
    //     if (hasPay) {
    //       this.curStep = 2;
    //       window.history.replaceState(
    //         null,
    //         null,
    //         `/reduce?o=${this.step2.order.orderNo}`
    //       );
    //     }
    //   }
    // );
    NiceModal.show(paymentModal, {
      orderNo: this.step2.order.orderNo,
      price: this.step2.order.totalAmount,
    }).then(() => {
      this.curStep = 2;
      window.history.replaceState(
        null,
        null,
        `/reduce?o=${this.step2.order.orderNo}`
      );
    });
  };

  onStep2Redeem = () => {
    Redeem({ ...this.step2.order, price: this.step2.order.totalAmount }).then(
      (hasPay) => {
        if (hasPay) {
          this.curStep = 2;
          window.history.replaceState(
            null,
            null,
            `/reduce?o=${this.step2.order.orderNo}`
          );
        }
      }
    );
  };

  onStep3GetOrderInfo = async (opts = {}) => {
    const res = await get(
      "/ai/ai/jc/query",
      {
        orderNo: opts.orderNo || this.step2.order.orderNo,
      },
      opts
    );

    this.step3.status = res.data ? 1 : 0;
    this.step3.loading = false;
    this.step3.list = res.data;
  };

  goBackStep1 = () => {
    this.curStep = 0;
  };
}

export const reduceStore = new ReduceStore();
