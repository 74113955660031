import styles from "../companyIntro/index.module.scss";
import { useDevice } from "../../hooks/useDevice";
import { InfoCircleFilled } from "@ant-design/icons";

// 产品
const ProductIntro = () => {
  const { isMobile } = useDevice();

  const innerContent = (
    <div
      className={"w-full  overflow-y-scroll"}
      style={{ padding: isMobile ? "" : "15px 10px 30px 15px" }}
    >
      <div className={styles.leftPopoverTitle}>产品简介</div>
      <div className={styles.leftPopoverContent}>
        青秧灵感，作为一款专为写作领域量身打造的智能辅助工具，致力于为广大创作者提供高效、便捷、可靠的创作支持。平台集成了全文写作、智能降重等核心功能，旨在帮助用户突破创作瓶颈，激发创作灵感，提升创作效率。
      </div>
      <div className={styles.leftPopoverTitle}>核心优势：</div>
      <div className={styles.leftPopoverContent}>
        海量资源，精准匹配：
        依托强大的数据库资源，青秧灵感能够为用户提供丰富、权威的文献资料，满足不同类型、不同领域的创作需求。
      </div>
      <div className={styles.leftPopoverContent}>
        智能降重，高效便捷：
        采用先进的自然语言处理技术，青秧灵感能够精准识别文本内容，并提供多种降重方案，有效降低重复率，提升作品原创性。
      </div>
      <div className={styles.leftPopoverContent}>
        用户口碑，值得信赖：
        青秧灵感自上线以来，已获得海量用户的高度评价，用户普遍认为其在提升创作效率、保障作品质量方面表现优异，是值得信赖的创作伙伴。
      </div>
      <div className={styles.leftPopoverContent}>应用场景：</div>
      <div className={styles.leftPopoverContent}>
        学术文章写作：
        提供海量学术资源，辅助文献综述、观点论证，并有效降低重复率，助力学术研究。
      </div>
      <div className={styles.leftPopoverContent}>
        商业文案创作：
        提供丰富的行业案例和数据分析，激发创意灵感，提升文案质量。
      </div>
      <div className={styles.leftPopoverContent}>
        文学创作：
        提供多样化的写作素材和灵感激发工具，帮助作家突破创作瓶颈，创作出更具吸引力的作品。
      </div>
      <div className={styles.leftPopoverContent}>
        青秧灵感，以科技赋能创作，激发无限可能。
        选择青秧灵感，开启您的创作新篇章！
      </div>
    </div>
  );

  return (
    <div className={"px-3 mx-auto pt-20 lg:w-[62.5vw]  lg:px-0"}>
      <div className={"mb-2"}>
        <InfoCircleFilled
          style={{
            color: "#24BE58",
            fontSize: "18px",
            marginRight: "6px",
          }}
        />
        <span className={"font-semibold text-2xl"}>产品介绍</span>
      </div>
      {innerContent}
    </div>
  );
};

export default ProductIntro;
