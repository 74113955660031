import {
  StarOutlined,
  DownloadOutlined,
  StarFilled,
  EllipsisOutlined,
  EditOutlined,
  FileAddOutlined,
  RightOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Space,
  Table,
  Tooltip,
  Modal,
  message,
  Pagination,
  Empty,
  Popover,
  Carousel,
  Divider,
  Spin,
} from "antd";
import Input from "antd/es/input/Input";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios, { get, post } from "../../axios/axios";
import styles from "./index.module.scss";
import orderDemo from "../../assets/order-demo.jpg";
import personNumImg from "../../assets/personNum.png";
import rocket from "../../assets/rocket.svg";
import dingdanchaxun from "../../assets/dingdanchaxun.svg";
import jiangchong from "../../assets/jiangchong.svg";
import ppt from "../../assets/ppt.svg";
import quanwenxiezuo from "../../assets/quanwenxiezuo.svg";
import { isMobile } from "../../common/const";
import { QueryOrder } from "../Buy/QueryOrder";
import { reduceStore } from "../Reduce/store";
import { download, handleDownLoad } from "../../Service/utils";
import jiantou from "../../assets/jiantou.svg";
import PPTUploadModal from "./components/PPTUploadModal";
import CountUp from "react-countup";
import { Footer } from "../../components/Footer";

export function Documents() {
  const [qs] = useSearchParams();
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(15);
  const [total, setToatal] = useState(0);
  const isFav = qs.get("folder") === "fav";
  const [activeTab, setActive] = useState(isFav ? "fav" : "all");
  const nav = useNavigate();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editArticle, setEditArticle] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const invQuey = qs.get("code") ? "?code=" + qs.get("code") : "";

  useEffect(() => {
    (async () => {
      const { data } = await get(
        "/member/documents/list",
        {
          size,
          current,
          favouriteFlag: activeTab === "fav" ? 1 : "",
          docType: activeTab === "write" ? 9 : "",
        },
        { slient: true }
      );
      setDataSource(data.records);

      setToatal(data.total);
    })();
  }, [current, size, activeTab]);
  const onSearch = async (params = "") => {
    console.log("p", params);
    const { data } = await get("/member/documents/list", {
      size,
      current,
      favouriteFlag: activeTab === "fav" ? 1 : "",
      docType: activeTab === "write" ? 9 : "",
      keyword: params,
    });
    setDataSource(data.records);
    setToatal(data.total);
  };

  const handleDelete = async (record) => {
    await post("member/documents/del/" + record.id);
    await onSearch();
  };

  const handleEditModalOk = async () => {
    await post("/member/documents/rename", {
      id: editArticle.id,
      title: editArticle.title,
    });
    await onSearch();
    setIsEditModalOpen(false);
  };
  const handleEditModalCancel = () => {
    setIsEditModalOpen(false);
  };

  const showEdit = (item) => {
    setIsEditModalOpen(true);
    setEditArticle({ ...item });
  };
  const handleEditInputChange = (e) => {
    const article = editArticle;
    setEditArticle({ ...article, title: e.target.value });
  };

  const handleFavorite = async (record) => {
    await post("/member/documents/favourite", {
      id: record.id,
      favouriteFlag: parseInt(record.favouriteFlag, 10) ? 0 : 1,
    });
    await onSearch();
  };

  const handleCreate = () => {
    nav("/construct" + invQuey);
  };

  const handleToAI = () => {
    nav("/dashboard/article");
  };

  const handleReduce = () => {
    nav("/reduce");
  };
  const handleDownloadReduce = () => {
    QueryOrder({
      confirm: (orderNo) => {
        return new Promise((resolve, reject) => {
          if (!orderNo) {
            message.info("请输入订单号");
            reject();
            return;
          }
          reduceStore.onStep3GetOrderInfo({ orderNo }).then(() => {
            resolve();
            window.open(`https://www.cqingyoung.com/reduce?o=${orderNo}`);
          });
        });
      },
    });
  };
  const renderRecord = (record) => {
    return (
      <div className={styles.wordCardCell} key={record.id}>
        <div
          className={styles.cardInner}
          onClick={() => {
            if (record.docType === 9 && record.status === 0) {
              message.info("正在快马加鞭生成中，请关注青秧灵感短信通知");
              return;
            }
            nav("/dashboard/workspace?txtid=" + record.id);
          }}
        >
          <div className={styles.wordCardCellTitleBox}>
            <div>
              <div className={styles.cardTitle}>
                <div className={styles.cardTitleTxt}>{record.title}</div>
              </div>
              <div className={styles.wordCardDesc}>{record.abbreviation}</div>
            </div>
          </div>
        </div>
        <div className={styles.wordCardTime}>{record.updateTime}</div>
      </div>
    );
  };

  const [videoVisible, setVideoVisible] = useState(false);
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [downlodType, setDownloadType] = useState("");

  const [downloadUrl, setDownloadUrl] = useState("");

  function showModal(type) {
    setDownloadUrl("");
    setOrderModalVisible(true);
    setDownloadType(type);
  }

  function openUrl(url) {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
  }

  const [loading, setLoading] = useState(false);

  // 拥有降重链接
  const [hasReduceUrl, setHasReduceUrl] = useState(false);

  const exportPaper = async () => {
    if (!orderNo) {
      message.error("请输入订单编号");
      return;
    }
    // 获取订单类型
    setLoading(true);
    const res = await get(`/ai/query/${orderNo}`);
    // 1 - 论文
    // 2 - PPT
    // 3 - 降重
    // -1 查询不到该订单
    if (res.data === -1) {
      setLoading(false);
      return;
    }
    // 如果是降重订单
    if (res.data === 3) {
      setLoading(false);
      setHasReduceUrl(false);
      reduceStore
        .onStep3GetOrderInfo({ orderNo })
        .then(() => {
          const reduceUrl = `https://www.cqingyoung.com/reduce?o=${orderNo}`;
          setHasReduceUrl(reduceUrl);
          window.open(reduceUrl);
        })
        .catch(() => {
          setHasReduceUrl(false);
        });
      return;
    }
    try {
      const { data, msg } = await get(
        res.data === 2
          ? `/ai/ai/ppt/status/${orderNo}`
          : `/ai/paper/status/${orderNo}`
      );
      if (res.data === 2) {
        if (data.url) {
          message.success("PPT已完成");
          download(data.url);
          setDownloadUrl(data.url);
          return;
        }
        if (data === null) {
          message.error(msg || "查询订单失败，请检查订单编号");
          return;
        }
        message.info("PPT正在加速生成中，请稍后重试");
      } else {
        if (data) {
          message.success("论文已完成");
          const url = "https://cqingyoung.com/ai/word/paper/export/" + orderNo;
          download(url);
          setDownloadUrl(url);
          return;
        }

        if (data === null) {
          message.error(msg || "查询订单失败，请检查订单编号");
        }

        if (data === false) {
          message.info("论文正在加速生成中，请稍后重试");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onInputChange = (e) => {
    setOrderNo(e.target.value);
  };
  const [demoListLoading, setDemoListLoading] = useState(true);
  const [demoList, setDemoList] = useState([]);
  useEffect(() => {
    (async () => {
      setDemoListLoading(true);
      const { data } = await get("/ai/paper/r").finally(() => {
        setDemoListLoading(false);
      });
      setDemoList(data);
    })();
  }, []);

  function downloadLunwen() {
    if (isMobile) {
      nav("/download");
    } else {
      showModal("lunwen");
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleUploadWord = () => {
    // 处理上传Word的逻辑
    console.log("上传Word");
    setIsModalVisible(false);
  };

  const handleWithoutWord = () => {
    // 处理不上传Word的逻辑
    console.log("不上传Word");
    setIsModalVisible(false);
  };

  const handlePPT = () => {
    setIsModalVisible(true);
    // nav('/pptprepare')
  };

  const cardList = [
    {
      title: "全文写作",
      desc: "撰写完整的学术论文，包括引言、文献综述、方法、结果、讨论和结论等。",
      icon: quanwenxiezuo,
      actionText: "去写作",
      onClick: handleCreate,
    },
    {
      title: "降重/降AIGC",
      desc: "减少论文中的重复内容或过度引用，以降低查重率或提高原创性。",
      icon: jiangchong,
      actionText: "去降重",
      onClick: handleReduce,
    },
    {
      title: "PPT",
      desc: "将研究内容、发现和结论以幻灯片形式展示的过程,用于口头报告或学术演讲。",
      icon: ppt,
      actionText: "去制作",
      onClick: handlePPT,
    },
    {
      title: "订单下载",
      desc: "填写订单号查询你所购买的订单内容，查询后可直接下载使用。",
      icon: dingdanchaxun,
      actionText: "去查询",
      onClick: downloadLunwen,
    },
  ];

  // 已生成人数
  const [personNum, setPersonNum] = useState(0);
  // 请求已生成人数
  useEffect(() => {
    get("/ai/number").then((res) => {
      setPersonNum(res.data);
    });
  }, []);
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.innerWrap}>
          <div
            className='
      p-4

      lg:mt-6
      lg:mb-0
      lg:flex
      lg:gap-2
    '
          >
            {/* 左侧banner */}
            <div
              className='
        bg-[#E8FFF9]
        rounded-xl
        p-4
        mb-4
        relative
        overflow-hidden

        lg:flex-1
        lg:p-4
        lg:pb-0
        lg:mb-0
      '
            >
              <div
                className='

          lg:mt-[12px]
        '
              >
                <div className='flex items-center'>
                  <div
                    className='
              text-[#333]
              text-2xl
              font-bold
              mr-2
              lg:text-[40px]
            '
                  >
                    青秧灵感
                  </div>
                  <div
                    className='
              bg-[#00CE72]
              text-white
              font-bold
              text-2xl
              px-2
              py-1
              rounded

              lg:text-[32px]
              lg:px-2
              lg:py-2
            '
                  >
                    全新升级
                  </div>
                </div>
                <div
                  className='
            text-[#999999]
            text-xs
            mt-3

            lg:text-sm
            lg:w-[496px]
            lg:mt-4
          '
                >
                  简单几步，即可完成论文写作，帮你节省更多时间，让你不再为写不出论文而烦恼，快来使用吧～
                </div>
              </div>
              <img
                src={rocket}
                alt=''
                className='
            absolute
            right-[0px]
            top-[8px]
            w-[116px]
            h-[52px]

            lg:w-[230px]
            lg:h-[129px]
            lg:right-[-20px]
            lg:top-[18px]
          '
              />
            </div>

            {/*h5统计*/}
            <div className='min-h-[42px] lg:hidden'>
              {demoList?.length > 0 && (
                <div
                  className='
                  flex
                  items-center
                  px-4
                  py-2
                  w-full

                  lg:px-4
                  lg:py-[10px]
                '
                >
                  <NotificationOutlined style={{ color: "rgb(36, 190, 88)" }} />
                  <div
                    className='
                  flex-1
                  ml-2
                  overflow-hidden

                  lg:w-[300px]
                  lg:ml-[10px]
                '
                  >
                    <Carousel
                      dotPosition='left'
                      autoplay={true}
                      autoplaySpeed={1000}
                      dots={false}
                    >
                      {demoList.map((item, idx) => (
                        <div
                          key={idx}
                          className='
                        text-sm
                        whitespace-nowrap
                        overflow-hidden
                        text-ellipsis

                        lg:text-base
                      '
                        >
                          {item}
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              )}
            </div>

            {/* 统计 */}
            <div
              className='
        hidden
        border
        border-[#00CE72]
        rounded-xl
        p-4
        relative

        lg:block
        lg:w-[26%]
        lg:pb-0
      '
            >
              <img src={personNumImg} alt='~' className='w-full' />
              <div
                className='
          absolute
          left-1/2
          -translate-x-1/2
          top-[30px]

          lg:w-[72%]
          lg:top-[48px]
        '
              >
                <div className='text-center'>
                  <span className='text-[#333] text-sm lg:text-[14px]   lg:leading-[14px]'>
                    已有
                  </span>
                  <span
                    className='
              text-[#00CE72]
              text-2xl
              lg:text-[24px]
              lg:font-bold
              lg:leading-[24px]
              lg:align-bottom
            '
                  >
                    <CountUp end={personNum} />
                  </span>
                  <span className='text-[#333] text-sm lg:text-[14px]'>人</span>
                </div>
                <div
                  className='
            text-center
            text-[#666666]
            text-sm
            mt-2
            lg:text-[14px]
            lg:leading-[14px]
          '
                >
                  成功生成论文
                </div>
              </div>

              {/* 轮播消息 */}
              {/* 右侧统计部分的轮播消息 */}
              <div className='mt-1 min-h-[42px]'>
                {demoListLoading ? (
                  <div
                    className={
                      "flex justify-center items-center px-4 py-2 w-full lg:px-4 lg:py-[10px]"
                    }
                  >
                    <Spin />
                  </div>
                ) : (
                  demoList?.length > 0 && (
                    <div
                      className='
                  flex
                  items-center
                  px-4
                  py-2
                  w-full

                  lg:px-4
                  lg:py-[10px]
                '
                    >
                      <NotificationOutlined
                        style={{ color: "rgb(36, 190, 88)" }}
                      />
                      <div
                        className='
                  flex-1
                  ml-2
                  overflow-hidden

                  lg:w-[300px]
                  lg:ml-[10px]
                '
                      >
                        <Carousel
                          dotPosition='left'
                          autoplay={true}
                          autoplaySpeed={1000}
                          dots={false}
                        >
                          {demoList.map((item, idx) => (
                            <div
                              key={idx}
                              className='
                        text-sm
                        whitespace-nowrap
                        overflow-hidden
                        text-ellipsis

                        lg:text-base
                      '
                            >
                              {item}
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className='w-full px-4 lg:px-8'>
            <div className='mt-6 mb-4 text-[#333] text-lg font-bold lg:mt-10 lg:text-xl'>
              {isMobile ? "写作中心" : "写作中心"}
            </div>

            <div className='grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-5'>
              {cardList.map((card, index) => (
                <div
                  key={index}
                  className='bg-white rounded-xl p-2 cursor-pointer shadow-[0_2px_4px_rgba(0,0,0,0.1)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.15)] transition-shadow lg:p-4 '
                  onClick={card.onClick}
                >
                  <div className='flex flex-col justify-start'>
                    <div className='flex w-full mb-2'>
                      <img
                        className='w-10 h-10 -translate-x-1.5'
                        src={card.icon}
                        alt={card.title}
                      />
                    </div>

                    <div className='flex-1'>
                      <div className='text-[18px] font-medium text-[#333]'>
                        {card.title}
                      </div>
                      <div className='mt-2 text-[14px] text-[#666] leading-[1.6] line-clamp-2'>
                        {card.desc}
                      </div>
                    </div>
                  </div>

                  <div className='w-full h-[1px] bg-[#F0F0F0] my-4'></div>

                  <div className='flex justify-between items-center'>
                    <span className='text-[#00Ce72]'>{card.actionText}</span>
                    <img
                      className='w-4 h-4 lg:w-6 lg:h-6'
                      src={jiantou}
                      alt='箭头'
                    />
                  </div>
                </div>
              ))}
            </div>

            <PPTUploadModal
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              onUploadWord={handleUploadWord}
              onWithoutWord={handleWithoutWord}
            />
          </div>
          <div className={"px-4 w-full lg:px-8 mt-10"}>
            <div className={"w-full flex justify-between"}>
              <div className={"mb-4  text-[#333] text-xl font-bold"}>范文</div>
              {/*h5搜索*/}
              <div className={"mt-5 hidden lg:block"}>
                <Search
                  placeholder='按标题或内容搜索'
                  onSearch={onSearch}
                  style={{ width: 300 }}
                />
              </div>
            </div>
            {/*pc搜索*/}
            <div className={"lg:hidden mt-2 mb-3"}>
              <Search
                placeholder='按标题或内容搜索'
                onSearch={onSearch}
                style={{ width: 300 }}
              />
            </div>

            <div className={""}>
              <div
                className='
                      grid
                      grid-cols-2
                      gap-2
                      lg:grid-cols-4
                      lg:gap-6
                    '
              >
                {dataSource.length === 0 ? (
                  <Empty
                    style={{
                      margin: "0 auto",
                      marginTop: "60px",
                    }}
                  />
                ) : (
                  dataSource.map((item) => renderRecord(item))
                )}
              </div>

              {!isMobile && dataSource.length !== 0 && (
                <div className={styles.paginationFooter}>
                  <Pagination
                    size='small'
                    current={current}
                    total={total}
                    pageSize={size}
                    onChange={(p, size) => {
                      setCurrent(p);
                      setSize(size);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <Modal
            title='重命名'
            open={isEditModalOpen}
            onOk={handleEditModalOk}
            onCancel={handleEditModalCancel}
          >
            <Input
              value={editArticle.title}
              onChange={handleEditInputChange}
              style={{ margin: "20px 0" }}
            />
          </Modal>
          <Modal
            title='如何使用'
            open={videoVisible}
            footer={null}
            width={850}
            destroyOnClose={true}
            onCancel={() => setVideoVisible(false)}
          >
            {videoVisible && (
              <video
                autoplay='autoplay'
                controls='controls'
                src='https://modeai.oss-cn-hangzhou.aliyuncs.com/introduce.mp4'
                style={{ width: "800px" }}
              ></video>
            )}
          </Modal>
          {/*订单下载*/}
          <Modal
            title={
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  请输入交易单号或兑换码下载
                  {downlodType === "ppt" ? "PPT" : "论文"}
                  <Popover
                    content={
                      <div>
                        <div style={{ marginBottom: "10px", width: "560px" }}>
                          温馨提示：
                          <div>
                            1.为了产出更高质量的文档，预计10分钟左右生成完成
                          </div>
                          <div>
                            2.文件下载流程：微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
                          </div>
                          <div>
                            3.所有论文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
                          </div>
                        </div>

                        <img
                          src={orderDemo}
                          style={{ width: "560px", height: "auto" }}
                        />
                      </div>
                    }
                    title='获取交易单号'
                    trigger='hover'
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                        color: "#666",
                      }}
                    >
                      如何获取交易单号
                    </span>
                  </Popover>
                </div>
              </div>
            }
            open={orderModalVisible}
            footer={null}
            width={850}
            destroyOnClose={true}
            onCancel={() => setOrderModalVisible(false)}
          >
            <div style={{ marginTop: "24px" }}>
              <Input
                size='large'
                placeholder='请输入交易'
                onChange={onInputChange}
              />
              {downloadUrl && (
                <a
                  href={downloadUrl}
                  target='_blank'
                  style={{
                    marginTop: "10px",
                    display: "block",
                    fontSize: "16px",
                  }}
                  rel='noreferrer'
                >
                  生成成功，点击此处手动下载
                </a>
              )}
              {hasReduceUrl && (
                <a
                  href={hasReduceUrl}
                  target='_blank'
                  style={{
                    marginTop: "10px",
                    display: "block",
                    fontSize: "16px",
                  }}
                  rel='noreferrer'
                >
                  生成成功，点击此处手动下载
                </a>
              )}
            </div>
            <div>
              <div style={{ marginBottom: "10px", width: "100%" }}>
                温馨提示：
                <div>1.为了产出更高质量的文档，预计10分钟左右生成完成</div>
                <div>
                  2.文件下载流程：支付宝/微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
                </div>
                <div>
                  3.所有论文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
                </div>
              </div>

              <img src={orderDemo} style={{ width: "100%", height: "auto" }} />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "24px",
                justifyContent: "flex-end",
              }}
            >
              <Button size='large' onClick={() => setOrderModalVisible(false)}>
                取消
              </Button>
              <Button
                size='large'
                type='primary'
                style={{ marginLeft: "20px" }}
                onClick={exportPaper}
                loading={loading}
              >
                确定
              </Button>
            </div>
          </Modal>
        </div>
        <div className={"mt-2 relative"}>
          <Footer />
        </div>
      </div>
    </>
  );
}
