import "./App.css";
import zhCN from "antd/locale/zh_CN";
import { Documents } from "./Article/Documents";
import { ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./common/common.scss";
import { Article } from "./Article";
import { Workspace } from "./Article/Workspace";
import { useEffect, useState } from "react";
import { loginInstance } from "./Service/login";
import { FloatButton, Popover } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";
import { Agreement } from "./Article/Agreement";
import { PPT } from "./Article/PPT";
import { PPTPrepare } from "./Article/PPTPrePare";
import { Reduce } from "./Article/Reduce";
import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import { Construct } from "./Article/Construct";
import { PayResult } from "./Article/PayResut";
import { Download } from "./Article/Download";
import CompanyIntro from "./pages/companyIntro/index";
import ProductIntro from "./pages/productIntro/index";
import { isMobile } from "./common/const";
import NiceModal from "@ebay/nice-modal-react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Article />,
    children: [
      {
        path: "",
        element: <Documents />,
      },
    ],
  },
  {
    path: "/dashboard/workspace",
    element: <Workspace />,
  },
  { path: "/construct", element: <Construct /> },
  { path: "/agreement", element: <Agreement /> },
  { path: "/pptprepare", element: <PPTPrepare /> },
  { path: "/ppt", element: <PPT /> },
  { path: "/success", element: <PayResult /> },
  { path: "/download", element: <Download /> },
  { path: "/reduce", element: <Reduce /> },
  { path: "/companyIntro", element: <CompanyIntro /> },
  { path: "/productIntro", element: <ProductIntro /> },
]);

function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const show = () => {
    if (!loginInstance.hasLogin) {
      loginInstance.open();
      return;
    }
    setOpen(true);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return loaded ? (
    <StyleProvider
      hashPriority='high'
      transformers={[legacyLogicalPropertiesTransformer]}
    >
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: "#24be58",
            colorInfoBg: "#fffbe6",
            colorInfoActive: "#f7941d",
            colorInfoText: "#f7941d",
          },
        }}
      >
        <NiceModal.Provider>
          <RouterProvider router={router} />
        </NiceModal.Provider>
        <Popover
          title={
            <div style={{ textAlign: "center", fontSize: "16px" }}>
              联系客服
            </div>
          }
          placement='topRight'
          content={
            <img
              src='https://exp1ore.oss-cn-beijing.aliyuncs.com/213213213.png'
              style={{ width: "180px", margin: "0 auto" }}
            />
          }
        >
          {isMobile ? null : (
            <FloatButton
              // description="联系客服"
              icon={
                <CustomerServiceOutlined
                  style={{ fontSize: "24px", marginLeft: "-2px" }}
                />
              }
              type='primary'
              style={{
                right: 24,
                width: "48px",
                height: "48px",
                bottom: "70px",
              }}
            />
          )}
        </Popover>
      </ConfigProvider>
    </StyleProvider>
  ) : (
    ""
  );
}

export default App;
