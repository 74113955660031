import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      {/*<div className={styles.footer}>*/}
      {/*    <div className={styles.footerTop}>*/}
      {/*        <div className={styles.box}>*/}
      {/*            <div className={styles.imgBox}>*/}
      {/*                <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbgzh.png" />*/}
      {/*            </div>*/}
      {/*            <div>关注公众号，获取更多论文技巧</div>*/}
      {/*        </div>*/}
      {/*        <div className={styles.box}>*/}
      {/*            <div className={styles.imgBox}>*/}
      {/*                <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbqq.png" />*/}
      {/*            </div>*/}
      {/*            <div>灵感风暴官方QQ群</div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <footer className='bg-white pb-4 pt-8'>
        <div className='pl-3 md:mx-auto md:w-[62.5vw] md:px-4'>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-3'>
            <div>
              <h3 className='mb-4 text-base font-semibold text-[#192038]'>
                关于我们
              </h3>
              <ul className='space-y-2'>
                <li>
                  <a
                    href={"/companyIntro"}
                    target={"_blank"}
                    style={{ color: "#24BE58" }}
                    className='text-sm text-[#414C6F] hover:text-gray-900'
                    rel='noreferrer'
                  >
                    公司介绍
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className='mb-4 text-base font-semibold text-[#192038]'>
                产品介绍
              </h3>
              <ul className='space-y-2'>
                <li>
                  <a
                    href={"/productIntro"}
                    target={"_blank"}
                    className='text-sm text-[#414C6F] hover:text-gray-900'
                    rel='noreferrer'
                    style={{ color: "#24BE58" }}
                  >
                    产品介绍
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className='mb-4 text-base font-semibold text-[#192038]'>
                联系我们
              </h3>
              <ul className='space-y-2'>
                <li className={"text-sm text-[#414C6F]"}>
                  客服邮箱：
                  <a
                    href='kf@cqingyoung.com'
                    className='hover:text-gray-900'
                    style={{ color: "#24BE58" }}
                  >
                    kf@cqingyoung.com
                  </a>
                </li>
                <li className={"text-sm text-[#414C6F]"}>
                  投诉反馈：
                  <a
                    href='ts@cqingyoung.com'
                    className='hover:text-gray-900'
                    style={{ color: "#24BE58" }}
                    rel='noreferrer'
                  >
                    ts@cqingyoung.com
                  </a>
                </li>
                <li className={"text-sm text-[#414C6F]"}>
                  商务合作：
                  <a
                    href='bd@cqingyoung.com'
                    className='hover:text-gray-900'
                    style={{ color: "#24BE58" }}
                    rel='noreferrer'
                  >
                    bd@cqingyoung.com
                  </a>
                </li>
                <li className={"text-sm text-[#414C6F]"}>
                  联系地址：
                  <span>长春市南关区新发路126号慧良企业服务A01室B区02号</span>
                </li>
                <li className={"text-sm text-[#414C6F]"}>
                  联系电话：
                  <span>17350738685</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={"flex justify-center"}>
          <a
            className={styles.beian}
            href='https://beian.miit.gov.cn/publish/query/indexFirst.action'
            target='_blank'
            style={{ color: "#24be58" }}
            rel='noreferrer'
          >
            吉ICP备2023005864号@2023 CQingYoung.COM |
            网信算备220102560107101240011号
          </a>
        </div>
      </footer>
    </>
  );
};
